import * as requestFromServer from "./coreApi";
import { coreSlice, callTypes } from "./coreSlice";

const { actions } = coreSlice;

export const fetchDomainInformation = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getDomainInformation(params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.domainInformationFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't get domain information";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrganization = (organizationId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getOrganization(organizationId)
    .then((response) => {
      const data = response.data;
      dispatch(actions.organizationFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't get organization";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const trackAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .track(params)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't track action";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const activeGroupAction = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .activeGroup(params)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't active group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getOrganizations = (params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getOrganizations(params)
    .then((response) => {
      const data = response.data;
      dispatch(actions.organizationsFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't get organization";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
