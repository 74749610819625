/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../common/_helpers";
import { DropdownTopbarItemToggler } from "../../../../../app/common/_partials/dropdowns";
import { useHistory } from "react-router-dom";
import "./scss/UserProfileDropdown.scss";
import { useEffect } from "react";
import { activeGroupAction, fetchOrganization, getOrganizations } from "redux/core/coreActions";
import { actions } from "redux/auth";

export function UserProfileDropdown() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { organization, organizations } = useSelector((state) => state.core);
  const history = useHistory();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);

  const activeGroup = (organizationId) => {
    const params = {
      organization_id: organizationId,
    };
    dispatch(activeGroupAction(params)).then((res) => {
      if (res.user) {
        dispatch(actions.login(res.user));
        dispatch(fetchOrganization(res.user.organization_id));
      }
    });
  };

  useEffect(() => {
    dispatch(getOrganizations({ user_id: user._id }));
  }, [user._id]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div className={"btn btn-icon w-auto btn-clean d-flex align-items-center btn-sm px-2"}>
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"></span>{" "}
          <span className="text-dark-50 font-weight-bold font-size-base d-none d-md-inline mr-3">
            {user?.name}
          </span>
          <span className="symbol symbol-30 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {user?.name && user?.name[0]}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{user?.name}</div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user.name && user.name[0]}
                </span>
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">{user?.name}</div>
            </div>
          )}
        </>
        <div className="navi navi-spacer-x-0 pt-5">
          {/* <div
            className="navi-item px-8 cursor-pointer"
            onClick={() => {
              window.open(`${window.location.origin}/studio`);
            }}
          >
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <img
                  src={`${toAbsoluteUrl("/media/logos/dlow-logo.png")} `}
                  className="w-20px"
                  alt=""
                />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">DLOW trình chiếu</div>
                <div className="text-muted">DLOW</div>
              </div>
            </div>
          </div> */}
          <div
            className="navi-item px-8 cursor-pointer"
            onClick={() => {
              history.push("/accountant#profile");
            }}
          >
            <div className="navi-link">
              <div className="navi-icon mr-2">
                {/* <img
                  src={`${toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} `}
                  className="w-20px"
                  alt=""
                /> */}
                <i className="fas fa-cog"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">Cài đặt</div>
              </div>
            </div>
          </div>
          <div
            className="navi-item px-8 cursor-pointer"
            onClick={() => {
              history.push("/accountant#account");
            }}
          >
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="fas fa-user-circle"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">Tài khoản</div>
              </div>
            </div>
          </div>
          <div className="navi-item px-8 cursor-pointer organization-dropdown">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="fas fa-caret-left icon-left-organization"></i>
                <i className="fas fa-sitemap"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">Tổ chức : {organization.name}</div>
              </div>
            </div>
            <div className="list-organization shadow">
              <div className=" font-weight-bold pb-2 text-uppercase title-organization">
                Thay đổi tổ chức
              </div>
              {organizations.length > 1 ? (
                organizations?.map((organizationItem) => {
                  if (organizationItem._id !== organization._id)
                    return (
                      <div
                        className="organizationItem"
                        key={organizationItem._id}
                        onClick={() => activeGroup(organizationItem._id)}
                      >
                        {organizationItem.name}
                      </div>
                    );
                })
              ) : (
                <div className="text-center">Không có tổ chức</div>
              )}
            </div>
          </div>
        </div>
        <div className="navi navi-spacer-x-0 pt-5">
          <div className="navi-footer  px-8 py-5">
            <Link to="/logout" className="btn btn-light-primary font-weight-bold">
              Đăng xuất
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
