import { createSlice } from "@reduxjs/toolkit";

const initialQuestionBank = {
  listLoading: false,
  actionsLoading: false,
  folders: [],
  countFolders: 0,
  foldersTree: [],
  folderDetail: {},
  questions: [],
  countQuestions: 0,
  templateDetail: {},
  syllabusDetail: {},
  lastError: null,
  hasMore: true,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const questionBankSlice = createSlice({
  name: "questionBank",
  initialState: initialQuestionBank,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    //FOLDER========

    foldersFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.folders = data.result;
      if (data.objects) {
        state.countFolders = data.objects.total;
      } else if (data.total) {
        state.countFolders = data.total;
      } else {
        state.countFolders = 0;
      }
    },

    foldersTreeFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.foldersTree = data.result;
    },

    folderDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.folderDetail = data.result;
      state.templateDetail = {};
    },

    //QUESTION========

    questionsFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.questions = data.result;

      if (data.objects) {
        state.countQuestions = data.objects.total;
      } else if (data.total) {
        state.countQuestions = data.total;
      } else {
        state.countQuestions = 0;
      }
    },

    updateQuestion: (state, action) => {
      const { question } = action.payload;
      const cloneQuestions = [...state.questions];
      for (let i = 0; i < cloneQuestions.length; i++) {
        if (question._id === cloneQuestions[i]._id) {
          cloneQuestions[i] = question;
          break;
        }
      }
      state.questions = cloneQuestions;
    },

    addQuestion: (state, action) => {
      const { data } = action.payload;
      state.questions = [...state.questions, data];
    },

    replaceListQuestion: (state, action) => {
      const { data } = action.payload;
      state.questions = data;
    },

    refreshQuestions: (state, action) => {
      state.hasMore = true;
      state.questions = [];
    },

    templateDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.templateDetail = data.result;
      state.folderDetail = {};
    },
  },
});
