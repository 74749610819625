import { createSlice } from "@reduxjs/toolkit";

const initialTextToSpeakState = {
  listLoading: false,
  actionsLoading: false,
  folders: [],
  countFolders: 0,
  foldersTree: [],
  folderDetail: {},
  textToSpeaks: [],
  countTextToSpeaks: 0,
  templateDetail: {},
  syllabusDetail: {},
  lastError: null,
  hasMore: true,
  dictionary: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const textToSpeakSlice = createSlice({
  name: "textToSpeak",
  initialState: initialTextToSpeakState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    foldersTreeFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.foldersTree = data.result;
    },

    folderDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.folderDetail = data.result;
      state.templateDetail = {};
    },

    //TEXT TO SPEAK========

    textToSpeakFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      if (data.result.length === 0) {
        state.hasMore = false;
      }
      if (state.hasMore) {
        state.textToSpeaks = [...state.textToSpeaks, ...data.result];
      }
      if (data.objects) {
        state.countTextToSpeaks = data.objects.total;
      } else if (data.total) {
        state.countTextToSpeaks = data.total;
      } else {
        state.countTextToSpeaks = 0;
      }
    },

    updateTextToSpeak: (state, action) => {
      state.textToSpeaks = action.payload;
    },

    dictionaryFetched: (state, action) => {
      const { data } = action.payload;
      state.dictionary = data.result;
    },

    refreshListTextToSpeak: (state, action) => {
      state.hasMore = true;
      state.templates = [];
    },

    templateDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.templateDetail = data.result;
      state.folderDetail = {};
    },
  },
});
