/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { checkIsActive } from "app/common/_helpers";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";

export function AsideMenuList({ layoutProps }) {
  return <div className="aside-menu"></div>;
}
