import Snackbar from "app/common/_partials/controls/Snackbar";
import React, { createContext, useState, useContext } from "react";

const SnackNotificationContext = createContext();

export function useSnackNotification() {
  return useContext(SnackNotificationContext);
}

export const SnackNotificationConsumer = SnackNotificationContext.Consumer;

export function SnackNotificationProvider({ children }) {
  const [snack, setSnack] = useState({
    open: false,
    id: null,
    message: "",
    variant: "success",
    vertical: "top",
    horizontal: "right",
  });

  const showSuccess = (message, props = {}) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: "success",
      ...props,
    });
  };

  const showError = (message, props = {}) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: "error",
      ...props,
    });
  };
  const showInfo = (message, props = {}) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: "info",
      ...props,
    });
  };

  const showWarning = (message, props = {}) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: "warning",
      ...props,
    });
  };

  const showDark = (message, props = {}) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: "dark",
      ...props,
    });
  };

  const value = { snack, setSnack, showSuccess, showError, showInfo, showWarning, showDark };

  return (
    <SnackNotificationContext.Provider value={value}>
      {children}
      <Snackbar
        vertical={snack.vertical}
        horizontal={snack.horizontal}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snack.open}
        options={snack}
        id={snack.id}
        variant={snack.variant}
        content={snack.message}
      />
    </SnackNotificationContext.Provider>
  );
}
