import { Input, ModalProgressBar } from "app/common/_partials/controls";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { InputPassword } from "app/common/_partials/controls/forms/InputPassword";
import { KEY_TOKEN } from "../../constants/localStorage";
import { loginTH } from "redux/auth";
import { useSnackNotification } from "app/layout/_core/SnackNotificationProvider";

export function ModalLoginWithTH({ show, onHide, onSaveSuccess, login }) {
  const { formatMessage: t } = useIntl();
  const snackNotification = useSnackNotification();

  const LoginSchema = Yup.object().shape({
    pass: Yup.string()
      .min(3, t({ id: "login_mininum_3_symbols" }))
      .max(50, t({ id: "login_maxinum_50_symbols" }))
      .required(
        t({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const [loading, setLoading] = useState(false);

  const initialValues = {
    lname: "",
    pass: "",
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setLoading(true);
          setTimeout(() => {
            loginTH({ ...values })
              .then(({ data }) => {
                setLoading(false);
                if (data) {
                  login(data);
                  localStorage.setItem(KEY_TOKEN, data.access_token);
                } else {
                  setSubmitting(false);
                  setStatus(
                    t({
                      id: "AUTH.VALIDATION.INVALID_LOGIN",
                    })
                  );
                }
              })
              .catch((e) => {
                snackNotification.showError("Tài khoản hoặc mật khẩu không chính xác");
                setLoading(false);
                setSubmitting(false);
                setStatus(
                  t({
                    id: "AUTH.VALIDATION.INVALID_LOGIN",
                  })
                );
              });
          }, 1000);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit }) => {
          return (
            <>
              <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                {loading && <ModalProgressBar />}
                <Form className="p-5">
                  <div className="font-size-h4 font-weight-bold text-center">
                    <div>ĐĂNG NHẬP</div> HỆ THỐNG BỒI DƯỠNG GVPT VÀ CBQLCSGDPT
                  </div>
                  <div className="py-5">
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Field
                          name="lname"
                          component={Input}
                          autoFocus
                          placeholder={"Tài khoản"}
                          label={"Tài khoản"}
                          focus
                          withFeedbackLabel
                          customFeedbackLabel
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Field
                          name="pass"
                          component={InputPassword}
                          autoFocus
                          placeholder={"Mật khẩu"}
                          label={"Mật khẩu"}
                          withFeedbackLabel
                          customFeedbackLabel
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center">
                    <div
                      className="btn btn-primary rounded w-100"
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Đăng nhập
                    </div>
                  </div>
                </Form>
              </Modal>
            </>
          );
        }}
      </Formik>
    </>
  );
}
