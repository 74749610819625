import { createSlice } from "@reduxjs/toolkit";

const initialMyContentPublishState = {
  listLoading: false,
  actionsLoading: false,
  folders: [],
  countFolders: 0,
  foldersTree: [],
  folderDetail: {},
  files: [],
  countFiles: 0,
  fileDetail: {},
  syllabusDetail: {},
  error: null,
  hasMore: true,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const myContentPublishSlice = createSlice({
  name: "myContentPublish",
  initialState: initialMyContentPublishState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    //FOLDER========

    foldersFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.folders = data.result;
      if (data.objects) {
        state.countFolders = data.objects.total;
      } else if (data.total) {
        state.countFolders = data.total;
      } else {
        state.countFolders = 0;
      }
    },

    foldersTreeFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.foldersTree = data.result;
    },

    folderDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.folderDetail = data.result;
      state.fileDetail = {};
    },

    //FILE========

    filesFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      if (data.result.length === 0) {
        state.hasMore = false;
      } else {
        state.hasMore = true;
      }
      if (state.hasMore) {
        state.files = [...state.files, ...data.result];
      }
      if (data.objects) {
        state.countFiles = data.objects.total;
      } else if (data.total) {
        state.countFiles = data.total;
      } else {
        state.countFiles = 0;
      }
    },

    refreshListFile: (state, action) => {
      state.hasMore = true;
      state.files = [];
    },

    fileDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.fileDetail = data.result;
      state.folderDetail = {};
    },

    deleteFileInRedux: (state, action) => {
      const cloneFiles = [...state.files];
      cloneFiles.splice(action.payload, 1);
      state.files = cloneFiles;
    },

    deleteManyFileInRedux: (state, action) => {
      const cloneFiles = [...state.files];
      const newListFile = cloneFiles.filter((file) => {
        return !action.payload.includes(file._id);
      });
      state.files = newListFile;
    },
  },
});
