import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../redux/auth";
import { librarySlice } from "../app/modules/Library/redux/librarySlice";
import { coreSlice } from "./core/coreSlice";
import { syllabusSlice } from "../app/modules/Syllabus/redux/syllabusSlice";
import { templateSlice } from "app/modules/Template/redux/templateSlice";
import { shareWithMeSlice } from "app/modules/ShareWithMe/redux/shareWithMeSlice";
import { myTemplateSlice } from "app/modules/MyTemplate/redux/myTemplateSlice";
import { myContentPublishSlice } from "app/modules/MyContentPublish/redux/myContentPublishSlice";
import { tagSlice } from "app/modules/Tag/redux/tagSlice";
import { questionBankSlice } from "app/modules/QuestionBank/redux/questionBankSlice";
import { accountantSlice } from "app/modules/Accountant/redux/AccountantSlice";
import { textToSpeakSlice } from "app/modules/TextToSpeak/redux/textToSpeakSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  library: librarySlice.reducer,
  template: templateSlice.reducer,
  shareWithMe: shareWithMeSlice.reducer,
  myTemplate: myTemplateSlice.reducer,
  myContentPublish: myContentPublishSlice.reducer,
  syllabus: syllabusSlice.reducer,
  tags: tagSlice.reducer,
  questionBank: questionBankSlice.reducer,
  accountant: accountantSlice.reducer,
  textToSpeak: textToSpeakSlice.reducer,
  core: coreSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
