import { createSlice } from "@reduxjs/toolkit";

const initialSyllabusState = {
  listLoading: false,
  actionsLoading: false,
  syllabusDetail: null,
  syllabusScormUrl: null
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const syllabusSlice = createSlice({
  name: "syllabus",
  initialState: initialSyllabusState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    syllabusDetailFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.syllabusDetail = data.result;
    },
    updateSyllabus: (state, action) => {
      const {data} = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.syllabusDetail = data.result;
    },
    getSyllabusScormUrl: (state, action) => {
      const {data} = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.syllabusScormUrl = data.result?.url;
    }
  }
});
