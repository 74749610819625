import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "./layout";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchOrganization } from "redux/core/coreActions";
import useVerifyAccount from "./components/verifyAccount/VerifyAccount";
import moment from "moment";

const LibraryPage = lazy(() => import("./modules/Library/pages/LibraryPage"));
const TemplatePage = lazy(() => import("./modules/Template/pages/TemplatePage"));
const ShareWithMePage = lazy(() => import("./modules/ShareWithMe/pages/ShareWithMePage"));
const MyTemplatePage = lazy(() => import("./modules/MyTemplate/pages/MyTemplatePage"));
const SyllabusEditorPage = lazy(() => import("./modules/Syllabus/pages/SyllabusEditorPage"));
const LessonEditorPage = lazy(() => import("./modules/Syllabus/pages/LessonEditorPage"));
const QuestionEditorPage = lazy(() => import("./modules/Syllabus/pages/QuestionEditorPage"));
const SyllabusPreviewPage = lazy(() => import("./modules/Syllabus/pages/SyllabusPreviewPage"));
const MyContentPublishPage = lazy(() =>
  import("./modules/MyContentPublish/pages/MyContentPublishPage")
);
const TagPage = lazy(() => import("./modules/Tag/pages/TagPage"));
const QuestionBankEditorPage = lazy(() =>
  import("./modules/QuestionBank/pages/QuestionBankEditorPage")
);
const AccountantPage = lazy(() => import("./modules/Accountant/pages/AccountantPage"));
const TexToSpeakPage = lazy(() => import("./modules/TextToSpeak/pages/TextToSpeakPage"));

export default function BasePage() {
  const organizationId = useSelector((state) => state.auth?.user?.organization_id, shallowEqual);
  const user = useSelector((state) => state.auth?.user, shallowEqual);
  const verifyAccount = useVerifyAccount();

  const dispatch = useDispatch();
  useEffect(() => {
    if (organizationId) {
      dispatch(fetchOrganization(organizationId));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (!user.phone) {
      const dayCreateUser = moment(user?.created_at).format("DD/MM/yyyy");
      const dayCurrent = moment().format("DD/MM/yyyy");
      if (dayCreateUser === dayCurrent && user?.firstLogin) {
        verifyAccount.setShowModalNotifyPackageTrial(true);
      }
    }
  }, [user]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/library" />
        }
        <Route path="/library" component={LibraryPage} />
        <Route path="/template" component={TemplatePage} />
        <Route path="/share-with-me" component={ShareWithMePage} />
        <Route path="/export/my-template" component={MyTemplatePage} />
        <Route path="/export/my-content-publish" component={MyContentPublishPage} />
        <Route path="/my-template" component={MyTemplatePage} />
        <Route path="/my-content-publish" component={MyContentPublishPage} />
        {/* <Route path="/question-bank" component={QuestionBankPage} /> */}
        <Route path="/tag" component={TagPage} />
        <Route path="/syllabus/editor/:id" component={SyllabusEditorPage} />
        <Route path="/syllabus/:id/lesson/editor/:lessonId" component={LessonEditorPage} />
        <Route path="/syllabus/:id/question/editor/:questionId" component={QuestionEditorPage} />
        <Route exact path="/syllabus/:id/preview" component={SyllabusPreviewPage} />
        <Route path="/syllabus/:id/preview/:lessonId" component={SyllabusPreviewPage} />
        <Route path="/question-bank/editor/:id" component={QuestionBankEditorPage} />
        <Route path="/accountant" exact component={AccountantPage} />
        <Route path="/accountant/:orderId" component={AccountantPage} />
        <Route path="/tools" component={TexToSpeakPage} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
