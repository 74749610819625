import { useContext, useState } from "react";
import { createContext } from "react";
import { Modal } from "react-bootstrap";
import React from "react";
import { Field, Form, Formik } from "formik";
import { Input } from "app/common/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as Yup from "yup";
import { actions, updateProfile } from "redux/auth";
import { Link } from "react-router-dom";
import "./verifyAccount.scss";

const VerifyContext = createContext();

export default function useVerifyAccount() {
  return useContext(VerifyContext);
}

export const VerifyAccountConsumer = VerifyContext.Consumer;

export function VerifyAccountProvider({ children }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user, shallowEqual);
  const dateRange = useSelector(
    (state) => state?.core?.organization?.balance?.plan?.package?.date_range,
    shallowEqual
  );
  const code = useSelector((state) => state?.core?.organization?.balance?.plan?.code, shallowEqual);
  const [showModalVerify, setShowModalVerify] = useState(false);
  const [showModalNotifyPackageTrial, setShowModalNotifyPackageTrial] = useState(false);
  const [initValues, setInitValues] = useState({ name: "", email: "", phone: "" });

  const verifyAccountSchema = Yup.object().shape({
    name: Yup.string().required("Họ và tên không được để trống"),
    phone: Yup.string()
      .matches(/^(84|0[3|5|7|8|9])+([0-9]{8})\b/g, {
        message: "Số điện thoại không chính xác",
        excludeEmptyString: true,
      })
      .required("Số điện thoại không được để trống"),
    email: Yup.string()
      .email("Email không đúng định dạng")
      .required("Email không được để trống"),
  });

  const verifyAccountSubmit = (values) => {
    updateProfile(values)
      .then((res) => {
        if (res?.data?.user) {
          dispatch(actions.updateUser(res.data.user));
          setShowModalVerify(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onHideModalTrial = () => {
    setShowModalNotifyPackageTrial(false);
    if (!user?.phone) {
      setShowModalVerify(true);
    }
  };

  useEffect(() => {
    if (user) {
      setInitValues({ name: user.name, phone: user.phone, email: user.email });
    }
  }, [user]);

  useEffect(() => {
    if (user?.firstLogin) {
      setShowModalNotifyPackageTrial(true);
      dispatch(actions.resetFirstLogin());
    }
  }, [user]);

  useEffect(() => {
    if (user && !user?.phone && code === "trial") {
      setShowModalVerify(true);
    }
  }, [user, code]);

  const value = { setShowModalVerify, setShowModalNotifyPackageTrial };

  return (
    <VerifyContext.Provider value={value}>
      {children}
      {showModalVerify && (
        <Modal show={showModalVerify} aria-labelledby="contained-modal-title-vcenter" centered>
          <div className="p-5">
            <div className="font-size-h4 font-weight-bold file-name">
              Xác nhận thông tin tài khoản
            </div>
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={initValues}
                validationSchema={verifyAccountSchema}
                onSubmit={(values) => {
                  verifyAccountSubmit(values);
                }}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({ handleSubmit, setFieldValue }) => {
                  return (
                    <Form>
                      <div className="pt-5">
                        <div className="font-weight-bold">
                          Để việc hỗ trợ nhanh chóng và chính xác, Anh/Chị vui lòng xác nhận thông
                          tin đăng ký tài khoán:
                        </div>
                        <div className="row form-group mt-4">
                          <div className="col-lg-12">
                            <Field
                              className="field-verify"
                              name="name"
                              component={Input}
                              autoFocus
                              label={"Họ và tên"}
                              placeholder={"VD: Nguyễn Văn A"}
                              focus
                              withFeedbackLabel
                              customFeedbackLabel
                            />
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-lg-12">
                            <Field
                              disabled={user?.email}
                              className="field-verify"
                              name="email"
                              component={Input}
                              label={"Email"}
                              placeholder={"VD: email@gmail.com"}
                              withFeedbackLabel
                              customFeedbackLabel
                            />
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-lg-12">
                            <Field
                              className="field-verify"
                              name="phone"
                              component={Input}
                              placeholder={"VD: 0123456789"}
                              label={"Số điện thoại"}
                              withFeedbackLabel
                              customFeedbackLabel
                              onChange={(event) => {
                                setFieldValue("phone", event.target.value.replace(/[^0-9]/gi, ""));
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-center ">
                        <Link
                          to="/logout"
                          className="btn btn-light rounded ml-2 w-130px"
                          onClick={() => setShowModalVerify(false)}
                        >
                          Tài khoản khác
                        </Link>
                        <div
                          className="btn btn-primary rounded ml-2 w-100px"
                          onClick={handleSubmit}
                        >
                          Lưu
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Modal>
      )}

      {showModalNotifyPackageTrial && (
        <Modal
          show={showModalNotifyPackageTrial}
          onHide={onHideModalTrial}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="p-5 d-flex flex-column align-items-center font-weight-bold position-relative">
            <i className="fas fa-times close-modal" onClick={onHideModalTrial}></i>
            <div className="modal-trial">
              <div className="register-success">Chúc mừng bạn đăng ký thành công</div>
              <div className="day-trial">
                <div>Bạn có</div>
                <div>{dateRange} ngày</div>
                <div>Dùng thử miễn phí</div>
              </div>
              <div className="wish">Chúc bạn có những trải nghiệm tuyệt vời với DLow!</div>
            </div>
          </div>
        </Modal>
      )}
    </VerifyContext.Provider>
  );
}
