import { createSlice } from "@reduxjs/toolkit";

const initialTagState = {
  listLoading: false,
  actionsLoading: false,
  folders: [],
  countFolders: 0,
  foldersTree: [],
  folderDetail: {},
  templates: [],
  countTemplates: 0,
  templateDetail: {},
  syllabusDetail: {},
  lastError: null,
  hasMore: true,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const tagSlice = createSlice({
  name: "tags",
  initialState: initialTagState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    //FOLDER========

    foldersFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.folders = data.result;
      if (data.objects) {
        state.countFolders = data.objects.total;
      } else if (data.total) {
        state.countFolders = data.total;
      } else {
        state.countFolders = 0;
      }
    },

    foldersTreeFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.foldersTree = data.result;
    },

    folderDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.folderDetail = data.result;
      state.templateDetail = {};
    },

    //FILE========

    templatesFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      if (data.result.length === 0) {
        state.hasMore = false;
      }
      if (state.hasMore) {
        state.templates = [...state.templates, ...data.result];
      }
      if (data.objects) {
        state.countTemplates = data.objects.total;
      } else if (data.total) {
        state.countTemplates = data.total;
      } else {
        state.countTemplates = 0;
      }
    },

    refreshListTemplate: (state, action) => {
      state.hasMore = true;
      state.templates = [];
    },

    templateDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.templateDetail = data.result;
      state.folderDetail = {};
    },
  },
});
