import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import * as auth from "../../../../redux/auth";
import { login, loginByToken } from "../../../../redux/auth";
import * as actions from "../../../../redux/core/coreActions";
import { useDispatch, useSelector } from "react-redux";
import { KEY_TOKEN } from "../constants/localStorage";
import { ModalLoginWithTH } from "./Modal/ModalLoginWithTH";
import { useQuery } from "./hooks/useQuery";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const domain = useSelector((state) => state?.core?.domain?.domain);
  const [showModalLoginWithTH, setShowModalLoginWithTH] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [googleAuthUrl, setGoogleAuthUrl] = useState("");
  const [facebookAuthUrl, setFacebookAuthUrl] = useState("");
  const query = useQuery();
  const token = query.get("token");
  const returnUrl = query.get("returnUrl");

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, t({ id: "login_mininum_3_symbols" }))
      .max(50, t({ id: "login_maxinum_50_symbols" }))
      .required(
        t({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      //return "is-valid";
    }

    return "";
  };

  const loginSuccess = (data) => {
    dispatch(actions.fetchOrganization(data.user.organization_id));
    props.login(data);
    localStorage.setItem(KEY_TOKEN, data.access_token);
    if (returnUrl) {
      window.open(decodeURI(returnUrl), "_self");
    }
  };

  useEffect(() => {
    const getUrl = async () => {
      const rs1 = await auth.loginWithGoogle();
      const rs2 = await auth.loginWithFb();
      setGoogleAuthUrl(rs1?.data?.url || "");
      setFacebookAuthUrl(rs2?.data?.url || "");
    };
    getUrl();
  }, []);

  useEffect(() => {
    if (token) {
      loginByToken(token)
        .then((res) => {
          if (res?.data) {
            loginSuccess(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login({ ...values, domain })
          .then(({ data }) => {
            disableLoading();

            if (data) {
              loginSuccess(data);
            } else {
              setSubmitting(false);
              setStatus(
                t({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              t({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <></>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={t({ id: "login_placeholder_account" })}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="box-input-password">
            <input
              placeholder={t({ id: "login_placeholder_password" })}
              type={showPassword ? "text" : "password"}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            <div className="toggle-password">
              <p className="mb-0 cursor-pointer font-size-sm text-dark-50" onClick={togglePassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </p>
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            //disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>{t({ id: "register_button_login" })}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      <div className="text-center">
        {t({ id: "register_account" })}{" "}
        <Link
          className="text-primary text-hover-primary my-3 mr-2 cursor-pointer"
          to="/auth/register"
        >
          {t({ id: "register_button" })}
        </Link>
      </div>
      <div
        className="form-group fv-plugins-icon-container text-center login-with-th mt-3"
        onClick={() => setShowModalLoginWithTH(true)}
      >
        Đăng nhập bằng tập huấn
      </div>
      <div
        className="form-group fv-plugins-icon-container text-center login-with-google mt-3"
        onClick={() => window.open(googleAuthUrl, "_self")}
      >
        <i className="fab fa-google text-white w-30px"></i>
        Đăng nhập với Google
      </div>
      <div
        className="form-group fv-plugins-icon-container text-center login-with-facebook mt-3"
        onClick={() => window.open(facebookAuthUrl, "_self")}
      >
        <i className="fab fa-facebook-f text-white w-30px"></i>
        Đăng nhập với Facebook
      </div>
      {/*end::Form*/}
      {showModalLoginWithTH && (
        <ModalLoginWithTH
          show={showModalLoginWithTH}
          onHide={() => {
            setShowModalLoginWithTH(false);
          }}
          login={props.login}
        />
      )}
    </div>
  );
}

export default connect(null, auth.actions)(Login);
