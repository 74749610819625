import React from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";
import "@formatjs/intl-relativetimeformat/dist/locale-data/vi";

import enMessages from "./messages/en";
import viBaseMessages from "./messages/vi";
import viCenterMessages from "./messages/vi-center";

import viErrors from "./errors/vi";
import viLibrary from "app/modules/Library/i18n/vi.json";
import viTemplate from "app/modules/Template/i18n/vi.json";
import viMyTemplate from "app/modules/MyTemplate/i18n/vi.json";
import viSyllabus from "app/modules/Syllabus/i18n/vi.json";
import viShareWithMe from "app/modules/ShareWithMe/i18n/vi.json";
import viTag from "app/modules/Tag/i18n/vi.json";
import viRegister from "app/modules/Auth/i18n/vi.json";
import vidBank from "app/modules/QuestionBank/i18n/vi.json";

const viMessages = () => {
  const viAllMessages = {
    ...viBaseMessages,
    ...viLibrary,
    ...viTemplate,
    ...viMyTemplate,
    ...viSyllabus,
    ...viShareWithMe,
    ...viRegister,
    ...viTag,
    ...vidBank,
  };

  if (window.VARIANT === "center") {
    return { ...viAllMessages, ...viCenterMessages };
  } else {
    return { ...viAllMessages };
  }
};

const allMessages = {
  en: enMessages,
  vi: viMessages(),
};

const allErrorMessages = {
  en: enMessages,
  vi: viErrors,
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = { ...allMessages[locale], ...allErrorMessages[locale] };

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
