import React from "react";
import "./expireTime.scss";
import { toAbsoluteUrl } from "app/common/_helpers";
import { useHistory } from "react-router-dom";

export default function ExpireTime() {
  const history = useHistory();

  const onClickToPackage = () => {
    history.push("/accountant#account");
  };

  return (
    <div className="expire-time">
      <img className="image-expire" src={toAbsoluteUrl("/media/bg/schedule.png")} alt="" />
      <div className="text-notification-expire">
        Gói hiện tại đã hết hạn, để tiếp tục sử dụng vui lòng mua thêm gói
      </div>
      <div className="button-extend" onClick={onClickToPackage}>
        Gia hạn
      </div>
    </div>
  );
}
