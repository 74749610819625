export const createMarkup = (html) => {
  return {
    __html: html,
  };
};

export const getTimeProgress = (course) => {
  let now = new Date().getTime();
  if (!course.start_date || !course.end_date || now < course.start_date * 1000) {
    return 0;
  }
  if (now > course.end_date * 1000) {
    return 100;
  }
  let progress =
    ((now - course.start_date * 1000) * 100) / (course.end_date * 1000 - course.start_date * 1000);
  return Math.round(progress);
};

export function compressImage(url, quality, callback) {
  const img = new Image();
  img.src = url;
  img.setAttribute("crossorigin", "anonymous");

  img.onload = function() {
    // Create a canvas element to draw the compressed image
    const canvas = document.createElement("canvas");
    const width = img.width;
    const height = img.height;
    canvas.width = width;
    canvas.height = height;

    // Draw the image on the canvas
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    // Convert the canvas content to a data URL with reduced quality
    const compressedImageUrl = canvas.toDataURL("image/jpeg", quality);

    // Call the callback function with the compressed image URL
    callback(compressedImageUrl);
  };
}
