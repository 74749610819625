import axios from "axios";

export const DOMAIN_INFORMATION_URL =
  window.API_BASE_URL + window.API_V1_PATH + "/site/get-information-by-domain";
export const ORGANIZATION_URL = (organizationId) =>
  window.API_DLOW_URL + `/api/organization/${organizationId}`;
export const TRACK_URL = window.API_DLOW_URL + `/api/library/track`;
export const ORGANIZATIONS_URL = window.API_DLOW_URL + `/api/organization/by-user`;
export const ACTIVE_GROUP_URL = window.API_DLOW_AUTH_URL + `/api/users`;

export function getDomainInformation(params) {
  var bodyFormData = new FormData();
  bodyFormData.append("_sand_hostname", window.APP_SCHOOL + "." + window.APP_SAAS_DOMAIN);

  return axios({
    method: "post",
    url: DOMAIN_INFORMATION_URL,
    data: bodyFormData,
  });
}

export function getOrganization(organizationId) {
  return axios({
    method: "get",
    url: ORGANIZATION_URL(organizationId),
  });
}

export function track(params) {
  return axios({
    method: "post",
    url: TRACK_URL,
    params: params,
  });
}

export function getOrganizations(params) {
  return axios({
    method: "get",
    url: ORGANIZATIONS_URL,
    params: params,
  });
}

export function activeGroup(params) {
  return axios.post(ACTIVE_GROUP_URL, params);
}
