import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useTimeExpire = () => {
  const timeExpire = useSelector((state) => state.core?.organization?.balance?.time_expire);
  const isTimeExpire = useMemo(() => {
    return moment(new Date()).isAfter(moment(timeExpire * 1000));
  }, [timeExpire]);
  return isTimeExpire;
};
