import React, { useEffect, useMemo, useRef } from "react";
import objectPath from "object-path";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../common/_helpers";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { HeaderMenu } from "./HeaderMenu";
import { useState } from "react";
import SearchModal from "../../extras/modals/SearchModal";
import "./headerMenuWrapper.scss";

export function HeaderMenuWrapper() {
  const uiService = useHtmlClassService();
  const modalSearchRef = useRef();
  const [openModalSearch, setOpenModalSearch] = useState(false);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      ktMenuClasses: uiService.getClasses("header_menu", true),
      rootArrowEnabled: objectPath.get(uiService.config, "header.menu.self.root-arrow"),
      menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
      headerMenuAttributes: uiService.getAttributes("header_menu"),
      headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
      ulClasses: uiService.getClasses("header_menu_nav", true),
      disabledAsideSelfDisplay: objectPath.get(uiService.config, "aside.self.display") === false,
    };
  }, [uiService]);
  const getHeaderLogo = () => {
    let result = "logo-dlow.png";
    if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
      result = "logo-dlow.png";
    }
    return toAbsoluteUrl(`/media/logos/${result}`);
  };

  const handleClickOutSide = (e) => {
    const { target } = e;
    if (!modalSearchRef.current?.contains(target)) {
      setOpenModalSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide);
    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  }, []);

  return (
    <>
      {/*begin::Header Menu Wrapper*/}
      <div
        className="header-menu-wrapper header-menu-wrapper-left d-flex align-items-center"
        id="kt_header_menu_wrapper"
      >
        {/* {layoutProps.disabledAsideSelfDisplay && ( */}
        <>
          {/*begin::Header Logo*/}
          <div className="header-logo">
            <Link to="/">
              <img alt="logo" className="max-w-200px" src={window.APP_LOGO || getHeaderLogo()} />
            </Link>
          </div>
          {/*end::Header Logo*/}
        </>
        {/* )} */}
        {/*begin::Header Menu*/}
        <div className="position-relative d-none">
          <div className="w-700px">
            <div className="w-500px ml-auto">
              <div className="input-group input-group-solid cursor-pointer">
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-search"></i>
                  </span>
                </div>
                <input type="text" className="form-control" placeholder="Tìm kiếm..." />
                <div
                  ref={modalSearchRef}
                  className="input-group-append cursor-pointer"
                  onClick={() => setOpenModalSearch(true)}
                >
                  <span className="input-group-text">
                    <svg width="24" height="24" viewBox="0 0 24 24" focusable="false">
                      <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"></path>
                    </svg>
                  </span>
                  {openModalSearch && (
                    <div className="modal-search rounded bg-white w-700px position-absolute shadow">
                      <SearchModal />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <HeaderMenu layoutProps={layoutProps} />
        {/*end::Header Menu*/}
      </div>
      {/*Header Menu Wrapper*/}
    </>
  );
}
