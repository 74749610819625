import { actions } from "../redux/auth";

function getDomain() {
  if (window.APP_SCHOOL && window.APP_SCHOOL !== "") {
    return window.APP_SCHOOL;
  } else {
    var subdomain = window.location.host.split(".")[1] ? window.location.host.split(".")[0] : false;
    if (subdomain) {
      return subdomain;
    } else {
      return "";
    }
  }
}

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (request) => {
      const {
        auth: { user },
      } = store.getState();
      const access_token = localStorage.getItem("access_token");

      if (!request.params) {
        request.params = [];
      }
      if ((user && user?.access_token) || access_token) {
        request.headers.Authorization = `Bearer ${user?.access_token || access_token}`;
      }

      return request;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (res) => {
      const originalConfig = res.config;
      if (originalConfig.url !== "/user/login" && res.data.err_code === 402) {
        store.dispatch(actions.logout());
      }

      return res;
    },
    (err) => {
      const originalConfig = err.config;

      if (originalConfig?.url !== "/user/login" && err.response) {
        if (err.response.status === 401) {
          store.dispatch(actions.logout());
        }
      }

      Promise.reject(err);
    }
  );
}
