import axios from "axios";

export const LOGIN_URL = window.API_DLOW_AUTH_URL + "/api/auth/login";
export const REGISTER_URL = window.API_DLOW_AUTH_URL + "/api/auth/register";
export const REQUEST_PASSWORD_URL = window.API_BASE_URL + "/user/forgot-password";
export const LOGIN_WITH_TH = window.API_DLOW_AUTH_URL + "/api/auth/th/login";
export const LOGIN_WITH_GOOGLE = window.API_DLOW_AUTH_URL + "/api/auth/google/url";
export const LOGIN_WITH_FB = window.API_DLOW_AUTH_URL + "/api/auth/facebook/url";
export const LOGIN_BY_TOKEN = window.API_DLOW_AUTH_URL + "/api/auth/login-by-token";
const EDIT_PROFILE_URL = window.API_DLOW_AUTH_URL + `/api/users`;

export const ME_URL = window.API_BASE_URL + "/api/me";

export function login(params) {
  const data = {
    email: params.email,
    password: params.password,
  };
  return axios({
    method: "post",
    url: LOGIN_URL,
    data: data,
    headers: { "Content-Type": "application/json" },
  });
}

export function loginTH(params) {
  const data = {
    lname: params.lname,
    pass: params.pass,
  };
  return axios({
    method: "post",
    url: LOGIN_WITH_TH,
    data: data,
    headers: { "Content-Type": "application/json" },
  });
}

export function loginByToken(token) {
  const params = {
    token: token,
  };
  return axios({
    method: "post",
    url: LOGIN_BY_TOKEN,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

export function register(email, name, password, phone) {
  return axios.post(REGISTER_URL, { email, name, password, phone });
}

export function requestPassword(email) {
  const bodyFormData = new FormData();
  bodyFormData.append("mail", email);

  return axios({
    method: "post",
    url: REQUEST_PASSWORD_URL,
    data: bodyFormData,
  });
}
export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function loginWithGoogle() {
  return axios({
    method: "get",
    url: LOGIN_WITH_GOOGLE,
  });
}

export function loginWithFb() {
  return axios({
    method: "get",
    url: LOGIN_WITH_FB,
  });
}

export function updateProfile(params) {
  let bodyFormData = new FormData();

  Object.keys(params).forEach((key) => {
    bodyFormData.append(key, params[key]);
  });

  return axios({
    method: "post",
    url: EDIT_PROFILE_URL,
    data: bodyFormData,
  });
}
