import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import * as auth from "../../../../redux/auth";
import { register, login } from "../../../../redux/auth";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const initialValues = {
  name: "",
  email: "",
  password: "",
  phone: "",
};

const phoneRegExp = /^(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

function Register(props) {
  const { formatMessage: t } = useIntl();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, t({ id: "register_required_min" }))
      .max(50, t({ id: "register_required_max" }))
      .required(
        t({
          id: "register_required_name",
        })
      ),
    email: Yup.string()
      .email(t({ id: "register_wrong_email_format" }))
      .min(3, t({ id: "register_required_min" }))
      .max(50, t({ id: "register_required_max" }))
      .required(
        t({
          id: "register_required_email",
        })
      ),
    password: Yup.string()
      .min(6, t({ id: "register_required_min" }))
      .max(50, t({ id: "register_required_max" }))
      .required(
        t({
          id: "register_required_password",
        })
      ),
    phone: Yup.string()
      .matches(phoneRegExp, t({ id: "login_wrong_phone_format" }))
      .required(
        t({
          id: "login_required_phone",
        })
      ),
  });

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegisterSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      register(values.email, values.name, values.password, values.phone)
        .then((res) => {
          const data = res.data?.data;
          if (data.email) {
            login({ email: data.email, password: values.password })
              .then(({ data }) => {
                disableLoading();
                if (data) {
                  props.login({ ...data, firstLogin: true });
                } else {
                  setSubmitting(false);
                  setStatus(
                    t({
                      id: "register_login_invalid",
                    })
                  );
                }
              })
              .catch(() => {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  t({
                    id: "register_login_invalid",
                  })
                );
              });
          }
          disableLoading();
          setSubmitting(false);
        })
        .catch((e) => {
          setSubmitting(false);
          setStatus(
            t({
              id: "register_invalid_email",
            })
          );
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-15">
        <h3 className="font-size-h1">{t({ id: "register" })}</h3>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Name */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={t({ id: "register_placeholder_name" })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Name */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={t({ id: "register_placeholder_email" })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <div className="box-input-password">
            <input
              placeholder={t({ id: "register_placeholder_password" })}
              type={showPassword ? "text" : "password"}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            <div className="toggle-password">
              <p className="mb-0 cursor-pointer font-size-sm text-dark-50" onClick={togglePassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </p>
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="box-input-password">
            <input
              placeholder={t({ id: "register_placeholder_phone" })}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "phone"
              )}`}
              name="phone"
              {...formik.getFieldProps("phone")}
            />
          </div>
          {formik.touched.phone && formik.errors.phone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone}</div>
            </div>
          ) : null}
        </div>
        {/* end: Phone */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          {/* <label className="checkbox justify-content-center">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
              {t({ id: "register_conditions" })}
            </Link>
            <span />
          </label> */}
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 w-100"
          >
            <span>{t({ id: "register_button" })}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      <div className="text-center">
        {t({ id: "login_account" })}{" "}
        <Link className="text-primary text-hover-primary my-3 mr-2 cursor-pointer" to="/auth/login">
          {t({ id: "register_button_login" })}
        </Link>
      </div>
    </div>
  );
}

export default connect(null, auth.actions)(Register);
