import { toAbsoluteUrl } from "app/common/_helpers";
import { Checkbox, Input, Select } from "app/common/_partials/controls";
import { AsyncSelectSearch } from "app/common/_partials/controls/forms/SelectSearch";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useIntl } from "react-intl";
import "./searchModal.scss";

export default function SearchModal() {
  const intl = useIntl();
  const t = intl.formatMessage;

  const typeLibrary = [
    { type: "img", label: t({ id: "library_detail_img" }) },
    { type: "pdf", label: t({ id: "library_detail_pdf" }) },
    { type: "doc", label: t({ id: "library_detail_doc" }) },
    { type: "excel", label: t({ id: "library_detail_excel" }) },
    { type: "audio", label: t({ id: "library_detail_audio" }) },
    { type: "video", label: t({ id: "library_detail_video" }) },
    { type: "ppt", label: t({ id: "library_detail_powerpoint" }) },
    { type: "folder", label: t({ id: "library_detail_folder" }) },
  ];

  const [selectTypeFile, setSelectTypeFile] = useState(typeLibrary[0]);

  const handleSelectTypeFile = (typeFile) => {
    setSelectTypeFile(typeFile);
  };

  return (
    <div className="p-6">
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values) => {}}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values }) => {
          return (
            <>
              <Form className="form form-label-right">
                <div className="form-group row align-items-center">
                  <div className="col-lg-2">{"Loại"}</div>
                  <div className="col-lg-10">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id="dropdown-organization"
                        className="w-100 text-left"
                      >
                        <div className="d-flex align-items-center">
                          {getIcon(selectTypeFile.type)}{" "}
                          <span className="ml-3">{selectTypeFile.label}</span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="w-100">
                        {typeLibrary.map((typeFile) => {
                          return (
                            <Dropdown.Item onClick={() => handleSelectTypeFile(typeFile)}>
                              <div className="d-flex align-items-center">
                                {getIcon(typeFile.type)}{" "}
                                <span className="ml-3">{typeFile.label}</span>
                              </div>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-2">{t({ id: "library_search_manager" })}</div>
                  <div className="col-lg-10">
                    <Select
                      name="manager"
                      customFeedbackLabel
                      withFeedbackLabel
                      className="form-control-solid form-search-control-select "
                    >
                      <option value={""}>{t({ id: "library_search_anyone" })}</option>
                      <option value={""}>{t({ id: "library_search_owned_by_me" })}</option>
                      <option value={""}>{t({ id: "library_search_not_owned_by_me" })}</option>
                      <option value={"other_user"}>
                        {t({ id: "library_search_a_particular_person" })}
                      </option>
                    </Select>
                    {values.manager === "other_user" && (
                      <div className="mt-2">
                        <AsyncSelectSearch
                          name="userManager"
                          isMulti
                          closeMenuOnSelect={false}
                          placeholder={t({
                            id: "library_search_enter_name_and_email",
                          })}
                          isClearable
                          defaultOptions
                          customFeedbackLabel
                          withFeedbackLabel
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-2">{t({ id: "library_search_there_are_words" })}</div>
                  <div className="col-lg-10">
                    <Field
                      name="content"
                      component={Input}
                      placeholder={t({ id: "library_search_find_in_file" })}
                      customFeedbackLabel
                      withFeedbackLabel
                      className={"form-search-control"}
                    />
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-2">{t({ id: "library_search_item_name" })}</div>
                  <div className="col-lg-10">
                    <Field
                      name="content"
                      component={Input}
                      placeholder={t({ id: "library_search_match_file" })}
                      customFeedbackLabel
                      withFeedbackLabel
                      className={"form-search-control"}
                    />
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-2">{t({ id: "library_search_location" })}</div>
                  <div className="col-lg-10">
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-organization">
                        {t({ id: "library_search_everywhere" })}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className="d-flex align-items-center">
                          <i className="fab fa-google-drive"></i>{" "}
                          {t({ id: "library_search_everywhere" })}
                        </Dropdown.Item>
                        <Dropdown.Item className="d-flex align-items-center">
                          <i className="fab fa-google-drive"></i>{" "}
                          {t({ id: "library_search_my_drive" })}
                        </Dropdown.Item>
                        <Dropdown.Item className="d-flex align-items-center">
                          <i className="fas fa-user-friends"></i>{" "}
                          {t({ id: "library_search_share_with_me" })}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="d-flex mt-3">
                      <div className="mr-7">
                        <Field
                          name="randomAnswer"
                          component={Checkbox}
                          label={t({ id: "library_search_starred" })}
                        />
                      </div>
                      <div>
                        <Field
                          name="randomAnswer"
                          component={Checkbox}
                          label={t({ id: "library_search_in_trash" })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-2">{t({ id: "library_search_updated_at" })}</div>
                  <div className="col-lg-10">
                    <Select
                      name="type"
                      customFeedbackLabel
                      withFeedbackLabel
                      className={"form-search-control"}
                    >
                      <option value={""}>{t({ id: "library_search_everywhere" })}</option>
                    </Select>
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-2">{t({ id: "library_search_shared" })}</div>
                  <div className="col-lg-10">
                    <Field
                      name="content"
                      component={Input}
                      placeholder={t({
                        id: "library_search_enter_name_and_email",
                      })}
                      customFeedbackLabel
                      withFeedbackLabel
                      className={"form-search-control"}
                    />
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export const getIcon = (type = "") => {
  if (type === "doc") {
    return <img width="20px" src={`${toAbsoluteUrl("/media/file/doc.png")}`} alt="" />;
  }
  if (type === "pdf") {
    return <img width="20px" src={`${toAbsoluteUrl("/media/file/pdf.png")}`} alt="" />;
  }
  if (type === "excel") {
    return <img width="20px" src={`${toAbsoluteUrl("/media/file/xls.png")}`} alt="" />;
  }
  if (type === "img") {
    return <img width="20px" src={`${toAbsoluteUrl("/media/file/image.png")}`} alt="" />;
  }
  if (type === "audio") {
    return <img width="20px" src={`${toAbsoluteUrl("/media/file/mp3.png")}`} alt="" />;
  }
  if (type === "video") {
    return <img width="20px" src={`${toAbsoluteUrl("/media/file/video.png")}`} alt="" />;
  }
  if (type === "ppt") {
    return <img width="20px" src={`${toAbsoluteUrl("/media/file/ppt.png")}`} alt="" />;
  }
  if (type?.replace(".", "") === "folder") {
    return <img width="20px" src={`${toAbsoluteUrl("/media/file/folder.png")}`} alt="" />;
  }
};
